import React from "react";
import Modal from "../Modal";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Owl from "../comp-layouts/Owl";

const getData = graphql`
  {
    file(relativePath: { eq: "lillian-stewart.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, AVIF, WEBP]
        )
      }
    }
  }
`;

const Lillian = () => {
  const data = useStaticQuery(getData);
  const image = data.file.childImageSharp.gatsbyImageData;
  return (
    <div>
      <Justify>
        <TestimonialImage image={image} alt="Lillian Stewart" />
      </Justify>
      <Name style={{ textAlign: "center", margin: "1.5rem 0" }}>
        Lillian Stewart
      </Name>
      <Modal buttonText="Read Testimonial">
        <Owl>
          <h3>Lillian Stewart</h3>
          <TestimonialImage image={image} alt="Lillian Stewart" />
          <p style={{ textAlign: "left" }}>
            Adrift Lab has provided me with so many incredible opportunities to
            collaborate with people and groups outside of the lab. I found it
            particularly valuable working alongside the Tasmanian Parks and
            Wildlife Service where I was able to perform demonstrations of my
            research to give a hands-on, immersive approach to science for the
            wider community.
          </p>
          <p style={{ textAlign: "left" }}>
            I was also able to extend my community outreach to school groups
            where we were able work together to remove many single-use plastics
            from the school canteens. I attribute these successes to the
            mentorship I was offered through Adrift Lab, which I bring with me
            to my PhD research. I feel confident to work in a professional
            setting where I liaise with stakeholders and communicate with a
            diverse array of individuals and groups vital to my research.
          </p>
        </Owl>
      </Modal>
    </div>
  );
};

export default Lillian;

const TestimonialImage = styled((props) => <GatsbyImage {...props} />)`
  max-width: 200px;
  margin: 1.5rem auto 0;
  border-radius: 9999px;
`;

const Justify = styled.div`
  display: flex;
  justify-items: center;
`;

const Name = styled.h3`
  font-size: 1.25rem;
`;
