import React from "react";
import Modal from "../Modal";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Owl from "../comp-layouts/Owl";

const getData = graphql`
  {
    file(relativePath: { eq: "profile_em_500.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, AVIF, WEBP]
        )
      }
    }
  }
`;

const Emma = () => {
  const data = useStaticQuery(getData);
  const image = data.file.childImageSharp.gatsbyImageData;
  return (
    <div>
      <Justify>
        <TestimonialImage image={image} alt="Emma Nichols" />
      </Justify>
      <Name style={{ textAlign: "center", margin: "1.5rem 0" }}>
        Emma Nichols
      </Name>
      <Modal buttonText="Read Testimonial">
        <Owl>
          <h3>Emma Nichols</h3>
          <TestimonialImage image={image} alt="Emma Nichols" />
          <p style={{ textAlign: "left" }}>
            Adrift Lab is made up of a highly passionate and supportive team.
            Through my experience with Adrift, I was given exposure to graphic
            design in an internal workshop, which has provided me with
            invaluable skills that can be transferable outside of academia.
            Looking back on my year of Honours with Adrift, I feel I now have
            greater confidence in scientific writing and continue to feel
            inspired by the team I was mentored by.
          </p>
        </Owl>
      </Modal>
    </div>
  );
};

export default Emma;

const TestimonialImage = styled((props) => <GatsbyImage {...props} />)`
  max-width: 200px;
  margin: 1.5rem auto 0;
  border-radius: 9999px;
`;

const Justify = styled.div`
  display: flex;
  justify-items: center;
`;

const Name = styled.h3`
  font-size: 1.25rem;
`;
