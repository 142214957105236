import React from "react";
import Modal from "../Modal";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Owl from "../comp-layouts/Owl";

const getData = graphql`
  {
    file(relativePath: { eq: "profile_morgan_500.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, AVIF, WEBP]
        )
      }
    }
  }
`;

const Morgan = () => {
  const data = useStaticQuery(getData);
  const image = data.file.childImageSharp.gatsbyImageData;
  return (
    <div>
      <Justify>
        <TestimonialImage image={image} alt="Morgan Gilmour" />
      </Justify>
      <Name style={{ textAlign: "center", margin: "1.5rem 0" }}>
        Morgan Gilmour
      </Name>
      <Modal buttonText="Read Testimonial">
        <Owl>
          <h3>Morgan Gilmour</h3>
          <TestimonialImage image={image} alt="Morgan Gilmour" />
          <p style={{ textAlign: "left" }}>
            Adrift Lab has cultivated a welcoming environment that was evident
            from the day of my arrival in Tassie. As an international postdoc, I
            was excited to work with Jenn, with whom I had collaborated
            previously. I didn't know anyone else in Tasmania prior to starting
            this position, but I was quickly adopted by the graduate students in
            the lab, which greatly smoothed my transition to postdoc life in
            Australia, both at the university and while exploring nightlife and
            wildlife on the weekends.
          </p>
          <p style={{ textAlign: "left" }}>
            The research conducted by Adrift Lab members is diverse, but often
            inter-connected; because of this diversity and the time-sensitive
            nature of quantifying and finding solutions towards marine
            pollution, there is a strong work ethic. I loved learning about
            everyone's projects and it is the first research lab in which I have
            actually felt part of a shared common goal. As a postdoctoral
            researcher, I had a large degree of independence during my project,
            but Jenn was available to provide support. One of the strongest
            aspects of the Adrift Lab are the outreach opportunities to
            communicate and share science with a very wide audience within
            Tasmania and Australia, and also globally.
          </p>
        </Owl>
      </Modal>
    </div>
  );
};

export default Morgan;

const TestimonialImage = styled((props) => <GatsbyImage {...props} />)`
  max-width: 200px;
  margin: 1.5rem auto 0;
  border-radius: 9999px;
`;

const Justify = styled.div`
  display: flex;
  justify-items: center;
`;

const Name = styled.h3`
  font-size: 1.25rem;
`;
