import React from "react"
import styled from "styled-components"

const Aligner = ({
  children,
  align,
  justify,
  textAlign,
  mTextAlign,
  zIndex,
  padding,
  wrap,
  direction,
}) => {
  return (
    <StyledAligner
      aAlign={align}
      aJustify={justify}
      aTextAlign={textAlign}
      aMTextAlign={mTextAlign}
      aIndex={zIndex}
      aPad={padding}
      aWrap={wrap}
      aDirection={direction}
    >
      {children}
    </StyledAligner>
  )
}

export default Aligner

const StyledAligner = styled.div`
  display: flex;
  align-items: ${props => props.aAlign || "center"};
  justify-content: ${props => props.aJustify || "none"};
  text-align: ${props => props.aTextAlign || "left"};
  flex-wrap: ${props => props.aWrap || "wrap"};
  flex-direction: ${props => props.aDirection || "column"};
  position: relative;
  z-index: ${props => props.aIndex || "1"};
  padding: ${props => props.aPad || "none"};
  box-sizing: border-box;
  height: 100%;
  @media (max-width: 768px) {
    text-align: ${props => props.aMTextAlign || props.aTextAlign || "left"};
  }
`
