import React from "react";
import Modal from "../Modal";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Owl from "../comp-layouts/Owl";

const getData = graphql`
  {
    file(relativePath: { eq: "megan-grant.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, AVIF, WEBP]
        )
      }
    }
  }
`;

const Megan = () => {
  const data = useStaticQuery(getData);
  const image = data.file.childImageSharp.gatsbyImageData;
  return (
    <div>
      <Justify>
        <TestimonialImage image={image} alt="Megan Grant" />
      </Justify>
      <Name style={{ textAlign: "center", margin: "1.5rem 0" }}>
        Megan Grant
      </Name>
      <Modal buttonText="Read Testimonial">
        <Owl>
          <h3>Megan Grant</h3>
          <TestimonialImage image={image} alt="Megan Grant" />
          <p style={{ textAlign: "left" }}>
            Adrift Lab is a very welcoming and supportive group of researchers
            and students. Jennifer goes above and beyond for all of her students
            and has implemented a number of extracurricular activities for those
            in her lab. These include writing retreats in Coles Bay, where we
            have had the opportunity to have dedicated one-on-one writing time
            with Jennifer or take part in writing sessions with the rest of the
            lab, along with nature walks and trips to the beach.
          </p>
          <p style={{ textAlign: "left" }}>
            Adrift Lab also participates in the Larapuna Community Weekend every
            year, where we venture to the beautiful Bay of Fires and remove
            debris from the beaches and have meaningful discussions on debris
            and marine life, and listen to Indigenous stories. Overall, I love
            being a part of Adrift Lab, because it's about more than just the
            research.
          </p>
        </Owl>
      </Modal>
    </div>
  );
};

export default Megan;

const TestimonialImage = styled((props) => <GatsbyImage {...props} />)`
  max-width: 200px;
  margin: 1.5rem auto 0;
  border-radius: 9999px;
`;

const Justify = styled.div`
  display: flex;
  justify-items: center;
`;

const Name = styled.h3`
  font-size: 1.25rem;
`;
