import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import styled from "styled-components";

const JoinBanner = ({ children }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "rainbow.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = placeholderImage.childImageSharp.gatsbyImageData;
  const pluginImage = getImage(image);

  return (
    <StyledBgImage tag="section" image={pluginImage}>
      {children}
    </StyledBgImage>
  );
};

export default JoinBanner;

const StyledBgImage = styled((props) => <BgImage {...props} />)`
  height: 20vh;
  min-height: 200px;
  @media (min-width: 1024px) {
    height: 40vh;
    min-height: 450px;
  }
`;
