import * as React from "react";
import Container from "../components/comp-layouts/Container";
import Grid from "../components/comp-layouts/Grid";
import Aligner from "../components/comp-layouts/Aligner";
import * as Icon from "react-feather";
import Owl from "../components/comp-layouts/Owl";
import styled from "styled-components";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import T from "../components/Text";
import Button from "../components/Button";
import theme from "../theme/theme.json";
import Modal from "../components/Modal";
import { Link } from "gatsby";
import Emma from "../components/testimonials/Emma";
import Morgan from "../components/testimonials/Morgan";
import Lillian from "../components/testimonials/Lillian";
import Megan from "../components/testimonials/Megan";
import JoinBanner from "../components/banners/JoinBanner";

const Join = () => {
  return (
    <>
      <SEO
        title="Join Our Research Group"
        description="Honours, Masters, and PhD students who wish to join Adrift Lab are encouraged to contact us. Specific projects are often available here in Australia, or overseas, with data, samples, or funding already in hand."
        slug="/join-adrift-lab"
      />
      <Layout>
        <JoinBanner />
        <Container mt="60px" mb="50px">
          <Owl>
            <h1>Join Adrift Lab</h1>
            <p>
              Honours, Masters, PhD students, and Post-doctoral Fellows who wish
              to join Adrift Lab are encouraged to contact us using the link
              above.{" "}
              <i>
                We are passionate about diversity and inclusion, and encourage
                applications from people of all identities and backgrounds.
              </i>
            </p>
            <p>
              At ETNTAC, we often have data and/or samples already in-hand and are actively looking for students to work with our team to analyse and write-up the findings. Note that the student needs to enrol with one of our collaborating organizations, such as Charles Sturt University or the University of Western Australia.
            </p>
            <p>
              Of course, you’re welcome to propose a project/topic based on your own expertise, interest, or location of prospective co-supervisors and/or funding. To see examples of the types of student projects that have been supported by Adrift Lab, see our <Link to="/current-students">Current Students</Link> or <Link to="/alumni">Alumni</Link> pages.
            </p>
            <p>
              <strong>
                Please make sure to include the following in your email:
              </strong>
            </p>
            <ul>
              <li>CV that clearly outlines your qualifications</li>
              <li>
                Cover letter that explains your interests (eg. are you applying
                for an advertised project, or proposing one yourself)
              </li>
              <li>
                Piece of relevant, recent, scientific writing that showcases
                your abilities. If you are including a journal article as your
                piece of writing (very important for PhD-level applicants), and
                you are not the first author, please detail in your cover letter
                what your contributions to this paper were.
              </li>
            </ul>
          </Owl>
          <Divider>
            <Icon.ChevronsDown color={theme.col.bluegrey[700]} />
          </Divider>
          <h2 style={{ marginBottom: "1.5rem" }}>Alumni Testimonials</h2>
          <TestimonialsHolder>
            <Emma />
            <Morgan />
            <Lillian />
            <Megan />
          </TestimonialsHolder>
          <Divider>
            <Icon.ChevronsDown color={theme.col.bluegrey[700]} />
          </Divider>
          <Aligner justify="center">
            <Button link="true" to="/contact" padding="20px">
              Contact Adrift Lab
            </Button>
          </Aligner>
        </Container>
      </Layout>
    </>
  );
};

export default Join;

const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  & ::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BannerContent = styled.div`
  text-align: center;
  padding: 20px;
  width: 90vw;
  max-width: 944px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  bottom: 6%;
  z-index: 4;
  @media (max-width: 600px) {
    & h1 {
      font-size: 36px;
    }
  }
  & a {
    margin: 10px auto 0;
    border-radius: 8px;
  }
`;

const BannerHeading = styled.h1`
  font-size: 48px;
  line-height: 1.2em;
  color: white;
  text-align: center;
`;

const CountPlacer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  padding: 5vw;
  height: 100%;
  align-items: flex-end;
  max-width: 1024px;
  margin: auto;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const TestimonialsHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 30px;
`;

const Divider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 40px 0;
  & ::before {
    content: "";
    position: absolute;
    display: block;
    height: 3px;
    width: 45%;
    left: 0;
    background-color: ${theme.col.bluegrey[100]};
  }
  & ::after {
    content: "";
    position: absolute;
    display: block;
    height: 3px;
    width: 45%;
    right: 0;
    background-color: ${theme.col.bluegrey[100]};
  }
`;
