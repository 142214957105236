import React, { useState } from "react";
import Button from "./Button";
import styled from "styled-components";
import * as Icon from "react-feather";
import theme from "../theme/theme.json";

const Modal = ({ buttonText, children, outline, textAlign }) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {outline ? (
        <Button style="outline" onClick={() => setShowPopup(!showPopup)}>
          {buttonText}
        </Button>
      ) : (
        <Button
          fs={theme.fs.sm}
          padding="10px"
          onClick={() => setShowPopup(!showPopup)}
        >
          {buttonText}
        </Button>
      )}

      {showPopup ? (
        <Overlay>
          <Container>
            <Content align={textAlign}>
              <Closer onClick={() => setShowPopup(!showPopup)}>
                <Icon.X />
              </Closer>
              {children}
            </Content>
          </Container>
        </Overlay>
      ) : null}
    </div>
  );
};

export default Modal;

const Overlay = styled.div`
  margin-top: 0 !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3000;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Container = styled.div`
  max-width: 90vw;
  width: 600px;
  max-height: 90vh;
  box-sizing: border-box;
  background-color: white;
  padding: 40px;
  overflow: scroll;
  border: 1px solid grey;
  margin: 20px auto 20px;
  position: relative;
`;

const Content = styled.div`
  text-align: ${(props) => props.align || "center"};
`;

const Closer = styled.button`
  position: absolute;
  border: none;
  background-color: white;
  right: 4px;
  top: 13px;
  transition: opacity 0.3s;
  & :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
